import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, zip, concat } from 'rxjs';
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';

import { DataService } from '../data.service';
@Component({
    selector: 'app-document-upload',
    template: `
    <ng-template #documentSide let-side="side" let-suffix="suffix" let-uploader="uploader" let-disabled="disabled">
        <div class="form-row">
            <div style="display:inline-block; text-align:center;">
                <p class="side-title">{{side}} side</p><br/>
                <img *ngIf="disabled" [id]="'downloadLink'+suffix" [src]="dataInvoice[suffix]" style="margin-bottom:20px;">
                <span class="btn btn-file" [ngClass]="{'disabled': suffix == 'F' ? disabledF: disabledB}" style="margin-right:10px">
                  <i class="fas fa-file-upload" style="margin-right:5px"></i> Upload
                  <input #fileinput type="file" class="btn btn-primary" ng2FileSelect [uploader]="uploader" [disabled]="disabled" />
                </span>
                <button class="btn btn-primary btn-file" [ngClass]="{'disabled': suffix == 'F' ? !disabledF: !disabledB}" (click)="delete(suffix)">
                  <i class="fas fa-trash-alt" style="margin-right:5px"></i> Delete
                </button>
            </div>
        </div>
    </ng-template>

        <div style="margin:0 10px; max-width:900px">
          <div>
            <span style="color:red;font-size:1.2em;margin-left:10px">{{errorMessage}}</span>
          </div>

          <div style="margin-bottom:20px">
            <a style="cursor:default" class="logo">
                 <img src="assets/img/logo.svg" alt="">
            </a>
          </div>

          <div *ngIf="parcel && pageno == 1" style="clear: both;">
<p>Hello, {{parcel.s_firstnm}} {{parcel.s_lastnm}}!</p>
<p>Your shipment {{parcel.code_a}} is almost ready.</p><br/>

<p>To proceed, please upload a copy of the recipient’s ID (Passport or Aadhaar Card) here: </p><br/>

<p class="form-row">Document Number: <input type="text" class="input" style="width: 155px; margin-left:6px" name="passportN" [(ngModel)]="ident.id_number"></p>
<p class="form-row">Document Type:
                                                <select type="text" class="input" style="width: 155px; margin-left:6px" name="passportS" [(ngModel)]="ident.pefp">
                                                    <option value="PS">Passport</option>
                                                    <option value="AC">Aadhaar Card</option>
                                                </select>
</p>

<div class="form-columns2 d-flex">
    <ng-container *ngTemplateOutlet="documentSide; context:{side: 'Front', suffix: 'F', uploader: uploaderF, disabled: disabledF}"></ng-container>
    <ng-container *ngTemplateOutlet="documentSide; context:{side: 'Back', suffix: 'B', uploader: uploaderB, disabled: disabledB}"></ng-container>
</div>

<p style="margin-top: 20px;">This step is required to ensure smooth shipping. Thank you for your prompt action!</p>
                <button class="btn btn-primary btn-file" style="margin-top:20px" (click)="pageno = 2" [disabled]="!disabledF || !disabledB">
                  <i class="fas fa-sign-out-alt" style="margin-right:5px"></i> Finish
                </button>
          </div>

          <div *ngIf="parcel && pageno == 2" style="clear: both;">
<p>Hello, {{parcel.s_firstnm}} {{parcel.s_lastnm}}!</p>
<p>Documents for your shipment {{parcel.code_a}} have been successfully uploaded.</p><br/>
<p>You can close this window now. Thank you for using Meest!</p>
          </div>

        </div>
`,
    styles: [`
        .sel-row { background-color: #10c0e0 !important; }
        .btn-file {
  position: relative;
  overflow: hidden;
  /*height: 100%;*/
  margin-top: 6px;

    background: #2361A9;
    border-radius: 100px;
    padding: 15px 31px;
    font-weight: 700;
    font-size: 15px;
    line-height: 109%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

  .btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }

.btn-file.disabled, .btn-file:disabled {
  opacity: 0.65;
  cursor:default;
}

.side-title {
    font-weight: bold;
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.8em;
}
`]
})
export class DocumentUploadComponent implements OnInit, AfterViewInit {
    errorMessage: string;
    loading: boolean;
    parcel: any;
    ident: any;
    docID: string;
    disabledF: boolean = false;
    disabledB: boolean = false;
    doclist: any[];
    dataInvoice: any = {};
    inited: boolean;
    pageno: number = 1;
    public uploaderF: FileUploader;
    public uploaderB: FileUploader;
    //@ViewChild('fileinput', { static: false }) input: ElementRef;

    constructor(public router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer, private dataService: DataService) {
        this.route.paramMap.subscribe(params => {
            this.docID = params.get('id');
            this.uploaderF = new FileUploader({ url: `${this.dataService.baseURL}api/DocList/Upload/${this.docID}/0` });
            this.uploaderB = new FileUploader({ url: `${this.dataService.baseURL}api/DocList/Upload/${this.docID}/1` });
        });
    }


    ngOnInit() {
        this.uploaderF.onAfterAddingFile = (fileItem: FileItem) => {
            this.checkPassport(fileItem);
        };
        this.uploaderF.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
            if (response === '')
                return;
            var responseObj = JSON.parse(response);
            if (responseObj.Success) {
                this.disabledF = true;
                this.doclist.push(responseObj.Name);
                this.getDocument(responseObj.Name, 'F');
                this.dataService.saveIdList(this.ident).subscribe((ui: any) => {
                });
            }
            else
                this.errorMessage = responseObj.Message;
        };

        this.uploaderB.onAfterAddingFile = (fileItem: FileItem) => {
            this.checkPassport(fileItem);
        };
        this.uploaderB.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
            if (response === '')
                return;
            var responseObj = JSON.parse(response);
            if (responseObj.Success) {
                this.disabledB = true;
                this.doclist.push(responseObj.Name);
                this.getDocument(responseObj.Name, 'B');
                this.dataService.saveIdList(this.ident).subscribe((ui: any) => {
                });
            }
            else
                this.errorMessage = responseObj.Message;
        };

        this.inited = false;
        zip(this.dataService.getShipForDoc(this.docID), this.dataService.getDocList(this.docID)).subscribe((res: any) => {
            var ui = res[0];
            if (!ui)
                this.errorMessage = 'Parcel not found';
            else {
                this.parcel = ui.s;
                this.ident = ui.i;
                this.doclist = res[1];
                if (!this.inited)
                    this.ngAfterViewInit();
            }
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    ngAfterViewInit() {
        if (this.doclist && this.doclist.length > 0) {
            var d = this.doclist.find(x => x.indexOf('_0') > 0);
            if (d) this.getDocument(d.toString(), 'F');
            var b = this.doclist.find(x => x.indexOf('_1') > 0);
            if (b) this.getDocument(b.toString(), 'B');

            this.inited = true;
        }
    }

    checkPassport(fileItem: FileItem) {
        var good = this.ident.id_number.match(/^([0-9]{12}|[A-Z][0-9]{7})$/i);
        if (good) {
            this.errorMessage = '';
            fileItem.isReady = true;
            fileItem.upload();
        }
        else
            this.errorMessage = 'Invalid document number';
    }

    getDocument(name: string, suffix: string) {
        this.dataService.getDocument(name).subscribe((ui: any) => {
            this["disabled" + suffix] = true;
            setTimeout(() => {
                let objURL = URL.createObjectURL(ui);
                this.dataInvoice[suffix] = this.sanitizer.bypassSecurityTrustUrl(objURL);
            //    var element = document.getElementById('downloadLink' + suffix) as HTMLAnchorElement;
            //    element.href = objURL;
            //    element.download = name;
            //    element.click();
            });
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    delete(suf: string) {
        this.dataService.deleteDoc(this.docID, suf == 'F' ? '0' : '1').subscribe((res: any) => {
            this["disabled" + suf] = false;
            var d = this.doclist.findIndex(x => x.indexOf('_' + (suf == 'F' ? '0' : '1')) > 0);
            if (d >= 0)
                this.doclist.splice(d, 1);
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }
}
