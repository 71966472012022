import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NavigationGuard } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from './login/login.component';
import { GuestComponent } from './login/guest.component';
import { NewParcel2Component } from './new-parcel/new-parcel2.component';
import { InvoiceComponentComponent } from './invoice-component/invoice-component.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentResultComponent } from './payment/payment-result.component';
import { RegistrationComponent } from './registration/registration.component';
//import { EstimatorComponent } from './new-parcel/estimator.component';
import { PopupEstimatorComponent } from './popup-estimator/popup-estimator.component';
import { NewMoneyComponent } from './new-money/new-money.component';
import { DocumentUploadComponent } from './new-parcel/upload-document.component';

export const router: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'guest', component: GuestComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'registration/:id', component: RegistrationComponent },
    { path: 'profile', component: RegistrationComponent },
    { path: 'newParcel', component: NewParcel2Component, canDeactivate: [NavigationGuard] },
    { path: 'newParcel/:type', component: NewParcel2Component, canDeactivate: [NavigationGuard] },
    { path: 'estimator', component: PopupEstimatorComponent },
    { path: 'invoice/:id/:isMoney', component: InvoiceComponentComponent },
  { path: 'payment/:id/:isMoney', component: PaymentComponent },
  { path: 'PaymentResult/:id', component: PaymentResultComponent },
    { path: 'newMoney', component: NewMoneyComponent },
    { path: 'UploadDocument/:id', component: DocumentUploadComponent },
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router);
